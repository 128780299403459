import React from "react";
import { Button } from "semantic-ui-react";
import * as styles from "./Example.module.scss";

const Example = () => {
  return (
    <section className={styles.exampleWrap}>
      <section className={styles.example}>
        <section className={styles.exampleRow}>
          <section className={styles.exampleLeft}>
            <p>{"When your pension is > £100,000"}</p>
            <p
              style={{
                fontSize: "55px",
                marginBottom: "2rem",
                fontFamily: "Times New Roman, Times, serif",
              }}
            >
              0.75%
            </p>
            <p>That Means £6.25 a month for every £10,000</p>
            <p>
              <i
                class='check circle icon'
                style={{ color: "#ae0f3b", fontSize: "1.5rem" }}
              ></i>
              £0 - £100k deposited
            </p>
          </section>
          <section className={styles.exampleRight}>
            <p>{"When your pension is < £100,000"}</p>
            <p
              style={{
                fontSize: "55px",
                marginBottom: "2rem",
                fontFamily: "Times New Roman, Times, serif",
              }}
            >
              0.56%
            </p>
            <p>That Means £4.67 a month for every £10,000 over £100,000</p>
            <p>
              <i
                class='check circle icon'
                style={{ color: "#ae0f3b", fontSize: "1.5rem" }}
              ></i>
              £100k+ deposited
            </p>
          </section>
        </section>
        <Button className={styles.getStartedButton}>Get Started</Button>
        <p>
          The value of your pension can go up as well as down. Capital at risk.
        </p>
      </section>
    </section>
  );
};

export default Example;
