import React from "react";
import * as styles from "./Footer.module.scss";
import { Icon } from "semantic-ui-react";

const Footer = () => {
  return (
    <section className={styles.footer}>
      <section></section>
      <section className={styles.fm}>
        <section className={styles.footerTop}>
          <section style={{ marginRight: "15rem" }}>
            <h3 style={{ marginTop: "2rem" }}>Main</h3>
            <div style={{ fontSize: "16px" }}>Home</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>App</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>
              Contact us
            </div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>
              Privacy policy
            </div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>Policies</div>
          </section>
          <section style={{ marginRight: "15rem" }}>
            <h3 style={{ marginTop: "2rem" }}>Resources</h3>
            <div style={{ fontSize: "16px" }}>Blog</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>Glossary</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>Videos</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>
              Pension Calculator
            </div>
          </section>
          <section>
            <h3 style={{ marginTop: "2rem" }}>Community</h3>
            <div style={{ fontSize: "16px" }}>Facebook</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>Linkedin</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>Instagram</div>
            <div style={{ fontSize: "16px", marginTop: "1rem" }}>Twitter</div>
          </section>
        </section>
        <section className={styles.footerBase}>
          <img
            src='https://assets-global.website-files.com/5ea82b59de6676f0fdee7210/5ea837992a28ee2f7ac58168_Long%20Logo%20Red.svg'
            alt='raindrop-logo'
            className={styles.logoImage}
          />
          <p className={styles.footerText}>
            Raindrop ® is a trading name of Raindrop Technologies Holdings Ltd
            (FRN: 931052) an Appointed Representative of Resolution Compliance
            Ltd, which is authorised and regulated by the Financial Conduct
            Authority (FRN: 574048). Raindrop Technologies Holdings Ltd is
            registered in England and Wales. Number: 12431555. Registered
            office: Runway East London Bridge, 20 St Thomas Street, London SE1
            9RS, UK.
          </p>
        </section>
      </section>
      <section className={styles.fr}>
        {" "}
        <img
          className={styles.doodle}
          src='https://assets-global.website-files.com/5ea82b59de6676f0fdee7210/5ea82b5984c5067fece3c77a_lines-13-black.svg'
          alt='doodle'
        />
        <i
          aria-hidden='true'
          class='circle icon huge'
          style={{
            position: "fixed",
            right: "1rem",
            bottom: "2rem",
            color: "#ae0f3b",
          }}
        ></i>
      </section>
    </section>
  );
};

export default Footer;
