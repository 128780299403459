import React from "react";
import * as styles from "./TrustPilot.module.scss";
import trust from "../../images/trust.png";
const TrustPilot = () => {
  return (
    <section className={styles.trustPilotWrap}>
      <section className={styles.trustPilot}>
        <img style={{ width: "95%" }} src={trust} alt='trust-pilot' />
      </section>
    </section>
  );
};

export default TrustPilot;
