import * as React from "react";
import Example from "../components/Example/Example";
import Footer from "../components/Footer/Footer";
import Mission from "../components/Mission/Mission";
import NavBar from "../components/NavBar/NavBar";
import TrustPilot from "../components/TrustPilot/TrustPilot";

const App = () => {
  return (
    <>
      <NavBar />
      <Mission />
      <Example />
      <TrustPilot />
      <Footer />
    </>
  );
};

export default App;
