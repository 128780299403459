import React, { useState } from "react";
import * as styles from "./Mission.module.scss";
import { Button } from "semantic-ui-react";
import InputForm from "../InputForm";

const Mission = () => {
  const [answer, setAnswer] = useState(null);

  return (
    <section className={styles.mission}>
      <section>
        <img
          src='https://assets-global.website-files.com/5ea82b59de6676f0fdee7210/5ea82b5984c506e63de3c866_Doodle-6-Dark.svg'
          alt='doodle'
        />
      </section>
      <section className={styles.misMid}>
        <Button className={styles.missionButton}>Fees</Button>
        <p
          style={{
            fontSize: "4rem",
            marginBottom: "1rem",
            fontFamily: "Times New Roman, Times, serif",
          }}
        >
          We're keeping it simple
        </p>
        <p style={{ fontSize: "1.5rem", marginBottom: "3rem" }}>
          At Raindrop, we understand you don't have time for complexity.
        </p>
        <p style={{ fontSize: "1.5rem" }}>
          Enter your estimated pension below to calculate our fees.
        </p>
        <InputForm setAnswer={setAnswer} />
        <p style={{ fontSize: "1.5rem", marginTop: "2rem" }}>
          Monthly fee of £{answer}
        </p>
      </section>
      <section className={styles.misRight}>
        <Button className={styles.feedbackButton}>Feedback</Button>
      </section>
    </section>
  );
};

export default Mission;
