import React from "react";
import * as styles from "./NavBar.module.scss";
import { Button } from "semantic-ui-react";

const NavBar = () => {
  return (
    <nav className={styles.navBar}>
      <section className={styles.navLeft}>
        <div>
          <img
            className={styles.logoImage}
            src='https://assets-global.website-files.com/5ea82b59de6676f0fdee7210/5ea837992a28ee2f7ac58168_Long%20Logo%20Red.svg'
            alt='rd-logo'
          />
        </div>
      </section>
      <section className={styles.navMiddle}>
        <a href='' className={styles.navLink}>
          Home
        </a>
        <a href='' className={styles.navLink}>
          About us
        </a>
        <a href='' className={styles.navLink}>
          How it Works
        </a>
        <a href='' className={styles.navLink}>
          Fees
        </a>
        <a href='' className={styles.navLink}>
          {" "}
          Funds
        </a>
        <a href='' className={styles.navLink}>
          Resources
        </a>
      </section>
      <section className={styles.navRight}>
        <Button basic className={styles.navButton}>
          {" "}
          Login
        </Button>
        <Button className={styles.navButtonRed}> Sign Up</Button>
      </section>
    </nav>
  );
};

export default NavBar;
